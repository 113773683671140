<template>
	<div>
		<div class="prod">
			<div class="prod-t"></div>
			<div class="prod-all">
				<div class="prod-all-l">
					<div class="prod-cate">
						<ul>
							<li>News</li>
							<li v-for="(item, index) in news_type" :key="index" @click="toggleType(item)">
								> {{ item.name }}
							</li>
						</ul>
					</div>

					<div class="prod-hot">
						<div class="tilte-h">Related News</div>
						<div class="newsList">
							<div class="lists" v-for="(item, index) in relate_news" :key="index"
								@click="toDetail(item.id)">
								<h2>{{ item.title }}s</h2>
								<div class="time">
									<span>{{ item.author }} </span>
									<span> {{ item.publish_date }}</span>
								</div>
								<div class="text">
									{{ item.introduction }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="prod-all-r">
					<div class="bread">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item>Home</el-breadcrumb-item>
							<el-breadcrumb-item>News</el-breadcrumb-item>
							<el-breadcrumb-item>Company news</el-breadcrumb-item>
							<el-breadcrumb-item>Name here</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div class="xian"></div>
					<div class="news-detail">
						<h2>{{ newObj.title }}</h2>
						<div class="info">
							Views : {{ newObj.read_count }} Author :
							{{ newObj.author }} Update time : {{ newObj.publish_date }}
						</div>
						<p v-html="newObj.content"></p>
						<div class="xian2"></div>
						<div class="shuming">
							<div @click="previoudtitle" v-if="previf"><span>PREVIOUS:</span> news title here</div>
							<div @click="nexttitle" v-if="nextif"><span>NEXT: </span>next news title here</div>
						</div>

						<div class="related-news">
							<h2>Related news</h2>

							<div class="related-news-list" v-if="relateNewsPage && relateNewsPage.length > 0">
								<a v-for="(item, index) in relateNewsPage" :key="index" @click="toDetail(item.id)">
									<img :src="item.img" alt="" />
									<div>
										{{ item.title }}
									</div>
									<div class="date">
										<span>{{ item.author }} </span>
										<span> {{ item.publish_date }}</span>
									</div>
									<div class="xinwen">
										{{ item.introduction }}
									</div>
								</a>
							</div>

							<!-- 分页 -->
							<div class="paging" v-if="relateNewsPage && relateNewsPage.length > 0">
								<el-pagination background :pager-count="5" layout="prev, pager, next" :total="totalPage*10"
									@current-change="togglePager">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getNewsDetailAPI,
		getNewsSideListAPI,
		getRelateNewsAPI,
	} from "@/assets/api/projectAPI";
	export default {
		name: "NewDetail",
		data() {
			return {
				relateNewsPage: [],
				relate_news: [],
				news_type: [],
				newObj: {},
				totalPage: null,
				previf:true,
				nextif:true,
				relateNewsCount: 0,
				params: {
					page: 1,
					id: this.$route.query.id,
				},
			};
		},
		methods: {
			previoudtitle(){
				 this.params.id=this.newObj.previous.id
				this.getNewsDetail()	
			},
			nexttitle(){
				this.params.id=this.newObj.next.id
				this.getNewsDetail()	
			},
			ifnextpre(){
				if(this.newObj.next.length!=0){
					this.nextif=true
				}else{
					this.nextif=false
				}
				
				if(this.newObj.previous.length!=0){
					this.previf=true
				}else{
					this.previf=false
				}
			},
			getNewsDetail() {
				getNewsDetailAPI(this.params.id).then((res) => {
					this.newObj = res.data;
					this.ifnextpre()
					console.log(res.data)
				});
			},
			getNewsType() {
				getNewsSideListAPI().then((res) => {
					if (res.code == 200) {
						this.news_type = res.data.news_type;
						this.relate_news = res.data.relate_news;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getRelateNews() {
				getRelateNewsAPI(this.params).then((res) => {
					if (res.code == 200) {
						this.totalPage = res.data.totalPage
						this.relateNewsPage = res.data.rows;
						this.relateNewsCount = res.data.count;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			toggleType(item) {
				this.$router.push({
					path: "/news",
					query: {
						type: item.code,
					},
				});
			},
			togglePager(page) {
				console.log(page)
				this.params.page = page;
				this.getRelateNews();
			},
			toDetail(id) {
				this.$router.push({
					query: {
						q: id
					}
				}).catch((err) => {});
				this.params.id = this.$route.query.q
				this.getNewsDetail();
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			},
		},
		mounted() {
			this.getNewsDetail();
			this.getNewsType();
			this.getRelateNews();
		},
		components: {},
	};
</script>
<style></style>
<template>
	<div>
		<div class="prod">
			<div class="prod-t"></div>
			<div class="prod-all">
				<div class="prod-all-l">
					<div class="prod-cate">
						<ul>
							<li>News</li>
							<li v-for="(item, index) in news_type" :key="index" @click="toggleType(item)">
								> {{ item.name }}
							</li>
						</ul>
					</div>

					<div class="prod-hot">
						<div class="tilte-h">Related News</div>
						<div class="newsList">
							<div class="lists" v-for="(item, index) in relate_news" :key="index"
								@click="toDetail(relate_news,item.id)">
								<h2>{{ item.title }}s</h2>
								<div class="time">
									<span>{{ item.author }} </span>
									<span> {{ item.publish_date }}</span>
								</div>
								<div class="text">
									{{ item.introduction }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="prod-all-r">
					<div class="bread">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item>Home</el-breadcrumb-item>
							<el-breadcrumb-item>News</el-breadcrumb-item>
							<el-breadcrumb-item>Company news</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div class="xian"></div>

					<div class="newAll">
						<div class="hot-news">
							<div class="hot-news-l" v-for="(item, index) in news" :key="index">
								<img :src="item.img" alt="" />
								<div class="hot-news-l-r">
									<h2 @click="toDetail(news,item.id)">{{ item.title }}</h2>
									<div class="time">{{ item.publish_date }}</div>
									<div class="nContent">
										{{ item.introduction }}
									</div>
									<div class="check">
										<a @click="toDetail(news,item.id)">check</a>
									</div>
								</div>
							</div>
						</div>

						<!-- 分页器 -->
						<div class="paging">
							<el-pagination background :pager-count="5" layout="prev, pager, next" :total="dataTotal"
								@current-change="togglePager">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getNewsSideListAPI,
		getNewsListAPI
	} from "@/assets/api/projectAPI";
	export default {
		name: "NewList",
		data() {
			return {
				relate_news: [],
				news_type: [],
				news: [],
				dataTotal: 0,
				params: {
					page: 1,
					type: "",
				},
			};
		},
		created() {
		    document.title = 'NEWS_YROFY:WISER CHOICE BETTER HOUSE';
		  },
		methods: {
			getNewsType() {
				getNewsSideListAPI().then((res) => {
					if (res.code == 200) {
						this.news_type = res.data.news_type;
						this.relate_news = res.data.relate_news;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getNewsList() {
				this.news = [];
				getNewsListAPI(this.params).then((res) => {
					if (res.code == 200) {
						this.news = res.data.rows;
						this.dataTotal = res.data.count;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			toggleType(item) {
				this.params.type = item.id;
				this.params.page = 1;
				this.dataTotal = 0;
				this.getNewsList();
			},
			togglePager(page) {
				this.params.page = page;
				this.getNewsList();
			},
			toDetail(item,id) {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
				this.$router.push({
					path: "/newDetail",
					query: {
						id:id
					},
				});
			},
			getUrlType() {
				let type = this.$route.query.type;
				if (type) {
					this.params.type = type;
				}
				this.$router.replace({
					query: {}
				}).catch((err) => {});
			},
		},
		mounted() {
			this.getUrlType();
			this.getNewsType();
			this.getNewsList();
		},
		components: {},
	};
</script>
<style></style>
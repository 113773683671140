import axios from "axios";
import { Message, Loading } from "element-ui";

export function request(config) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      // 
      baseURL: 'https://api.yrofy.com/',
      timeout: 50000,
    })
    // 添加请求拦截器
    instance.interceptors.request.use(config => {
      let langue = localStorage.getItem('langue');
      let langueValue = JSON.parse(langue).value
      // if (config.method == 'post') {
      //   config.data.lang = langueValue
      // } else {
      //   config.params.lang = langueValue
      // }
      config.url += (config.url.indexOf('?') === -1 ? '?' : '&') + 'lang=' + langueValue;
      return config;
    }, function (error) {
      // 对请求错误做些什么
      Message.error("请求失败！")
    });

    // 添加响应拦截器
    instance.interceptors.response.use(response => {
      return response.data;
    }, function (error) {
      Message.error("响应失败！")
    });
    instance(config)
      .then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
  })
}
// get请求

export function get(api, params) {
  return new Promise((reslove, reject) => {
    request({
      url: api,
      method: 'get',
      params: params
    }).then(res => {
      reslove(res)
    }).catch((err) => {
      reject(err)
    })
  })
};

// post请求

export function post(api, data,) {

  return new Promise((reslove, reject) => {

    request({
      url: api,
      method: 'post',
      data: data
    }).then(res => {
      reslove(res)
    }).catch((err) => {
      reject(err)
    })

  })

};

//put请求

export function put(api, data) {

  return new Promise((reslove, reject) => {

    request({
      url: api,
      method: 'put',
      data: data
    }).then(res => {
      reslove(res)
    }).catch((err) => {
      reject(err)
    })

  })

};

export function del(api, data) {

  return new Promise((reslove, reject) => {

    request({
      url: api,
      method: 'delete',
      data: data
    }).then(res => {
      reslove(res)
    }).catch((err) => {
      reject(err)
    })

  })

};


export function postFormData(api, formData) {
  return new Promise((resolve, reject) => {
    request({
      url: api,
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
 
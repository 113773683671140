<template>
  <div class="prod">
    <div class="prod-t"></div>
    <div class="prod-all">
      <div class="prod-all-l">
        <div class="prod-cate">
          <ul>
            <li>Product</li>
            <li
              v-for="(item, index) in product_type"
              :key="index"
              @click="toggleType(item)"
            >
              > {{ item.type_name }}
            </li>
          </ul>
        </div>
			<div class="merch" v-if="isWideEnough">
					<div class="prod-hot">
						<div class="tilte-h">Hot Products</div>
						<ul>
							<li v-for="(item, index) in hot_product" :key="index" @click="toDetail(item.id)">
								<img :src="item.images" alt="" />
								<span>{{ item.product_name }}</span>
							</li>
						</ul>
					</div>
				</div>
       
      </div>

      <div class="prod-all-r">
        <div class="bread">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>Home</el-breadcrumb-item>
            <el-breadcrumb-item>Product</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="xian"></div>

        <div class="merch">
          <div class="merch_list">
            <a
              v-for="(item, index) in productList"
              :key="index"
              @click="toDetail(item.id)"
            >
              <div class="sum">
                <img :src="item.images" alt="" />
              </div>
              <h2>
                {{ item.product_name }}
              </h2>
            </a>
          </div>

          <!-- 分页器 -->
          <div class="paging">
            <el-pagination
              background
              :pager-count="5"
              layout="prev, pager, next"
              :total="dataTotal"
              @current-change="togglePager"
            >
            </el-pagination>
          </div>
		  <div class="prod-hot" v-if="!isWideEnough">
		    <div class="tilte-h">Hot Products</div>
		    <ul>
		      <li v-for="(item, index) in hot_product" :key="index" @click="toDetail(item.id)">
		        <img :src="item.images" alt="" />
		        <span>{{ item.product_name }}</span>
		      </li>
		    </ul>
		  </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProdSideListAPI, getProdListAPI } from "@/assets/api/projectAPI";
export default {
  name: "ProductList",
  data() {
    return {
		isWideEnough: window.innerWidth > 1372,
      hot_product: [],
      product_type: [],
      productList: [],
      dataTotal: 0,
      params: {
        page: 1,
        type: "",
      },
    };
  },
  created() {
      document.title = 'PRODUCT_YROFY:WISER CHOICE BETTER HOUSE';
    },
  methods: {
    getProdType() {
      getProdSideListAPI().then((res) => {
        if (res.code == 200) {
          this.product_type = res.data.product_type;
          this.hot_product = res.data.hot_product;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getProdList() {
      this.productList = [];
      getProdListAPI(this.params).then((res) => {
        if (res.code == 200) {
          this.productList = res.data.rows;
          this.dataTotal = res.data.count;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    toggleType(item) {
      this.params.type = item.id;
      this.params.page = 1;
      this.dataTotal = 0;
      this.getProdList();
      window.scrollTo({top: 0,behavior: "smooth"});
    },
    togglePager(page) {
      this.params.page = page;
      this.getProdList();
    },
    toDetail(id) {
      this.$router.push({
        path: "/productDetail",
        query: {
          q: id,
        },
      });
    },
	handleResize() {
	      this.isWideEnough = window.innerWidth > 1372;
	    },
    getUrlType() {
      let type = this.$route.query.type;
      if (type) {
        this.params.type = type;
      }
      this.$router.replace({ query: {} }).catch((err) => {});
      window.scrollTo({top: 0,behavior: "smooth"});
    },
  },
  beforeRouteUpdate(to, from, next) {
    // 在路由更新时触发，可以在这里进行数据的刷新操作
    next();
  },
  mounted() {
	window.addEventListener('resize', this.handleResize);
    this.getUrlType();
    this.getProdType();
    this.getProdList();
  },
  components: {},
};
</script>
<style></style>

<template>
  <div id="app">
    <Main/>
  </div>
</template>
<script>
  import Main from '@/views/Main'

  export default {
    components:{
      Main
    }, 

  }
</script>

<style>
</style>

<template>
	<div class="contact-us">
		<div class="top-t"></div>
		<div class="main-a">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>Home</el-breadcrumb-item>
				<el-breadcrumb-item>Contact Us</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="main-a-b">
				<span class="b-1">Consult us to get a FREE CUSTOM QUOTE</span>
				<span class="b-2">Tell us your custom needs and we will quote a best price for
					you</span>
			</div>
			<div class="main-a-c">
				<div class="main-a-c-left">
					<el-form :model="messageForm" label-width="80px" label-position="top" :rules="messageFormRules"
						ref="messageForm">
						<el-row :gutter="20">
							<el-col :span="12">
								<el-form-item label="Name *" prop="name">
									<el-input v-model="messageForm.name"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="Email *" prop="email">
									<el-input v-model="messageForm.email"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-form-item label="Tel / Whatsapp *" prop="tel">
									<el-input v-model="messageForm.tel"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="Company">
									<el-input v-model="messageForm.company"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-form-item label="Country">
									<el-input v-model="messageForm.country"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="Quantity">
									<el-input v-model="messageForm.quantity"></el-input>
								</el-form-item>
							</el-col>
						</el-row>

						<el-row :gutter="20">
							<el-col>
								<el-form-item label="Have you ever imported from China? *" style="display: flex">
									<el-radio-group v-model="messageForm.from_china" style="margin-left: 20px">
										<el-radio :label="1">Yes</el-radio>
										<el-radio :label="0">No</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col>
								<el-form-item label="Message *" prop="content">
									<el-input type="textarea" :rows="5"
										placeholder="We will send you samples and catalogs later !"
										v-model="messageForm.content"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-form-item>
							<el-button @click="submitMessage">
								<div>Send Inquiry Now</div>
								<img src="~assets/img/btn.png" alt="" />
							</el-button>
						</el-form-item>
					</el-form>
				</div>

				<div class="main-a-c-right">
					<div class="right-img">
						<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/kefu-p.png" alt="" />
					</div>
					<div class="right-text">
						<span>Chat with a live</span>
						<span>Let's chat with our live experts to get an answer your
							questions.</span>
					</div>
					<div class="right-button">
						<el-button>
							<div>Call or Email Us Now</div>
						</el-button>
					</div>
				</div>
			</div>

			<div class="contactWay">
				<div class="contactWay_l"  :class="{ 'animate__animated animate__slideInUp':ifcontactWay_l}">
					<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/contact.png" alt="" />
					<h2>Contact</h2>
					<div>Packy Hu</div>
				</div>
				<div class="contactWay_r"  :class="{ 'animate__animated animate__slideInUp':ifcontactWay_r}">
					<div class="con_top">
						<div class="tujing">
							<img src="~assets/img/email.png" alt="" />
							<div class="right">
								<div class="title">Email</div>
								<span>packy@yrofy.com</span>
							</div>
						</div>
						<div class="tujing">
							<img src="~assets/img/phone.png" alt="" />
							<div class="right">
								<div class="title">Tel</div>
								<span>0086-579-87329098</span>
							</div>
						</div>
						<div class="tujing">
							<img src="~assets/img/whatsApp.png" alt="" />
							<div class="right">
								<div class="title">WhatsApp</div>
								<span> +86 15727941972</span>
							</div>
						</div>
						<div class="tujing">
							<img src="~assets/img/weixin.png" alt="" />
							<div class="right">
								<div class="title">Wechat</div>
								<span>packyhu2023</span>
							</div>
						</div>

						<div class="tuijing2">
							<img src="~assets/img/address.png" alt="" />
							<div class="right">
								<div class="title">Address</div>
								<span>No.26 Zerong Road, Dahou Industrial Zone, Tangxian Town,
								</span>
								<span>Yongkang City, Zhejiang Province, 321300, China</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="map-container"  :class="{ 'animate__animated animate__slideInUp':ifmap_container}">
				<baidu-map class="map" :center="{ lng: 120.109299, lat: 29.008805 }" :zoom="15">
					<bm-marker :position="{ lng: 120.109299, lat: 29.008805 }" :dragging="true"
						animation="BMAP_ANIMATION_BOUNCE">
						<!-- 地图内部的其他组件，如标记点等 -->
						<bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true"
							:autoLocation="true"></bm-geolocation>
						<bm-label content="YROFY" :labelStyle="{ color: 'red', fontSize: '24px' }"
							:offset="{ width: -35, height: 30 }" />
					</bm-marker>
				</baidu-map>
				<!--        google Map-->
				<!--        <GmapMap-->
				<!--          :center="{ lat: 10, lng: 10 }"-->
				<!--          :zoom="7"-->
				<!--          map-type-id="terrain"-->
				<!--        >-->
				<!--          &lt;!&ndash; 添加标记 &ndash;&gt;-->
				<!--          <GmapMarker-->
				<!--            :position="{ lat: 10, lng: 10 }"-->
				<!--            :clickable="true"-->
				<!--            :draggable="false"-->
				<!--            @click="markerClicked"-->
				<!--          ></GmapMarker>-->
				<!--        </GmapMap>-->
			</div>
		</div>
	</div>
</template>
<script>
	import {
		messageAPI
	} from "@/assets/api/projectAPI";

	export default {
		name: "ContactUs",
		data() {
			return {
				messageForm: {
					name: "",
					email: "",
					tel: "",
					company: "",
					country: "",
					quantity: "",
					from_china: -1,
					content: "",
				},
				messageFormRules: {
					name: [{
						required: true,
						message: " ",
						trigger: "blur"
					}],
					email: [{
						required: true,
						message: " ",
						trigger: "blur"
					}],
					tel: [{
						required: true,
						message: " ",
						trigger: "blur"
					}],
					content: [{
						required: true,
						message: " ",
						trigger: "blur"
					}],
				},
				ifcontactWay_l:false,
				ifcontactWay_r:false,
				ifmap_container:false,
			};
			
		},
		created() {
			document.title = 'CONTACT US_YROFY:WISER CHOICE BETTER HOUSE';
			window.addEventListener("scroll", this.handleScroll);
		},
		methods: {
			handleScroll() {
				const contactWay_lElement = document.querySelector(".contactWay_l");  
				const contactWay_rElement = document.querySelector(".contactWay_r");
				const map_containerElement = document.querySelector(".map-container");
				// console.log(series_listElement)
				if (contactWay_lElement) {
					const elementfot = contactWay_lElement.getBoundingClientRect();
					if (this.ifcontactWay_l) {
						this.ifcontactWay_l = true
					} else {
						this.ifcontactWay_l = elementfot.top <= window.innerHeight && elementfot.bottom >= 0;
					}
				}
				if (contactWay_rElement) {
					const elementfot = contactWay_rElement.getBoundingClientRect();
					if (this.ifcontactWay_r) {
						this.ifcontactWay_r = true
					} else {
						this.ifcontactWay_r = elementfot.top <= window.innerHeight && elementfot.bottom >= 0;
					}
				}
				if (map_containerElement) {
					const elementfot = map_containerElement.getBoundingClientRect();
					if (this.ifmap_container) {
						this.ifmap_container = true
					} else {
						this.ifmap_container = elementfot.top <= window.innerHeight && elementfot.bottom >= 0;
					}
				}
				
				},
			// 提交留言
			submitMessage() {
				this.$refs["messageForm"].validate((valid) => {
					if (valid) {
						//转换参数
						const formData = new FormData();
						for (let key in this.messageForm) {
							formData.set(key, this.messageForm[key]);
						}
						//请求发送
						messageAPI(formData).then((res) => {
							if (res.code == 200) {
								this.$message.success(res.message);
								for (let key in this.messageForm) {
									this.messageForm[key] = "";
								}
							} else {
								this.$message.error(res.message);
							}
						});
					} else {
						return false;
					}
				});
			},
			markerClicked() {
				console.log("Marker clicked!");
			},
		},
		mounted() {},
		components: {},
	};
</script>
<style></style>
import { request,postFormData } from "@/utils/request";

// 首页获取商品
export function getHomePageProjectAPI() {
    return request({
        url: `/index/index/getHomePageProduct`,
        method: 'get',
        params:{}
    })
}

// 首页显示用户评价
export function getHomePageReviewsAPI() {
    return request({
        url: `/index/index/getHomePageReviews`,
        method: 'get'
    })
}

// 留言
export function messageAPI(formData) {
    return postFormData('/index/index/message',formData)
}

// 新闻页面-左侧
export function getNewsSideListAPI() {
    return request({
        url: `/index/news/getSideList`,
        method: 'get'
    })
}

// 新闻列表
export function getNewsListAPI(params) {
    return request({
        url: `/index/news/getList`,
        method: 'get',
        params:params
    })
}

// 新闻详情
export function getNewsDetailAPI(id) {
    return request({
        url: `/index/news/getDetail?id=${id}`,
        method: 'get'
    })
}


// 商品页面-左侧
export function getProdSideListAPI() {
    return request({
        url: `/index/product/getSideList`,
        method: 'get'
    })
}

// 商品列表
export function getProdListAPI(params) {
    return request({
        url: `/index/product/getList`,
        method: 'get',
        params:params
    })
}

// 商品详情
export function getProdDetailAPI(id) {
    return request({
        url: `/index/product/getDetail?id=${id}`,
        method: 'get'
    })
}

// 商品详情
export function getRelateNewsAPI(params) {
    return request({
        url: `index/news/getRelateNews`,
        method: 'get',
        params:params
    })
}


<template>
	<div class="aboutus">
		<div class="aboutus-t"></div>
		<div class="aboutus-main-a">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>Home</el-breadcrumb-item>
				<el-breadcrumb-item>About Us</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="aboutus-main-b">
			<span class="aboutus-title">About Yrofy</span>
			<div class="aboutus-text">
				<p>
					Yrofy is your most trusted partner in wholesale home storage products.
				</p>
				<br />
				<br />
				<p>
					Here at Yrofy, we’ve been producing household products for over
					twenty-five years. We has won the unanimous trust of our global
					customers through our strong production capacity and strict product
					quality control.
				</p>
				<br />
				<br />
				<p>
					We believe that a tidy house is the guarantee of a light mind, so we
					specialize in combining the consumer demand with production skills. We
					always keep up with the trend of the global market to provide
					innovative and high quality products, such as clothes tree, hanger,
					cart and all kinds of shelves, which gained consumers’ recognition and
					love in the international market.
				</p>
			</div>
			<div class="aboutus-video" :class="{ 'animate__animated animate__slideInUp':ifabout_video}">
				<video poster="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/vedio_image.jpeg" controls :src="require('@/assets/img/aboutus-v.mp4')"></video>
			</div>
		</div>
		<div class="aboutus-main-c" :class="{ 'animate__animated animate__slideInUp':ifaboutus_c}">
			<span class="aboutus-title">Yrofy's Vision</span>
			<div class="aboutus-vision">
				<div class="vision-item">
					<img src="~assets/img/vision-1.png" alt="" />
					<div class="item-text">
						<span class="text-0">Zero excuses - Zero complaints - </span>
						<span class="text-1">BE PRACTICAL</span>
					</div>
				</div>
				<div class="vision-item">
					<img src="~assets/img/vision-2.png" alt="" />
					<div class="item-text">
						<span class="text-0">Zero procrastination - Zero repetition - </span>
						<span class="text-1">BE EFFICIENT</span>
					</div>
				</div>
				<div class="vision-item">
					<img src="~assets/img/vision-3.png" alt="" />
					<div class="item-text">
						<span class="text-0">Zero carelessness - zero mistakes - </span>
						<span class="text-1">BE PRECISE</span>
					</div>
				</div>
				<div class="vision-item">
					<img src="~assets/img/vision-4.png" alt="" />
					<div class="item-text">
						<span class="text-0">Speaking with</span>
						<span class="text-1"> HONESTY </span>
						<span class="text-0">and acting with</span>
						<span class="text-1"> LOYALTY </span>
					</div>
				</div>
				<div class="vision-item">
					<img src="~assets/img/vision-5.png" alt="" />
					<div class="item-text">
						<span class="text-0">Working together in </span>
						<span class="text-1">UNITY</span>
						<span class="text-1"> and </span>
						<span class="text-1">COOPERATION, INNOVATING AND SURPASSING EXPECTATIONS.</span>
					</div>
				</div>
			</div>
		</div>
		<div class="aboutus-main-d" :class="{ 'animate__animated animate__slideInUp':ifaboutus_d}">
			<div>
			<div class="aboutus-control">
				<span class="aboutus-title">Quality Control Process</span>
					<div id="imgvist">
						<div class="aboutus-process">
							<div class="process-item">
								<img src="~/assets/img/process-1.png" alt="" />
								<span>Sample<br>confirmation</span>
							</div> 
							<img src="~assets/img/process-step.png" alt="" class="steps" />
							<div class="process-item">
								<img src="~/assets/img/process-2.png" alt="" />
								<span>Verify raw<br>
								materials</span>
							</div>
							<img src="~assets/img/process-step.png" alt="" class="steps" />
							<div class="process-item">
								<img src="~/assets/img/process-3.png" alt="" />
								<span>Semi-finished<br>product<br>inspection</span>
							</div>
							<img src="~assets/img/process-step.png" alt="" class="steps" />
							<div class="process-item">
								<img src="~/assets/img/process-4.png" alt="" />
								<span>Packaging<br>Inspection</span>
							</div>
							<img src="~assets/img/process-step.png" alt="" class="steps" />
							<div class="process-item">
								<img src="~/assets/img/process-5.png" alt="" />
								<span>Final test</span>
							</div>
							<img src="~assets/img/process-step.png" alt="" class="steps" />
							<div class="process-item">
								<img src="~/assets/img/process-6.png" alt="" />
								<span>Production<br>sample<br>confirmation</span>
							</div>
							<img src="~assets/img/process-step.png" alt="" class="steps" />
							<div class="process-item">
								<img src="~/assets/img/process-7.png" alt="" />
								<span>Supervised<br>loading</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="aboutus-main-e"  :class="{ 'animate__animated animate__slideInUp':ifaboutus_d}">
			<div class="manu">
				<div class="manu-l">
					<h2>Our Manufacturing Center</h2>
					<div class="context">
						<h4>For more than 25 years</h4>
						<div class="text">
							we have been sticking to the field of metal racks and strictly
							controlling product quality.
						</div>
						<h4>
							Today, we have over 200 workers, technical quality control teams,
						</h4>
						<div class="text">
							and a professional factory with a monthly production capacity of
							more than 2 million pieces, covering an area of more than 15,000
							square meters.
						</div>
						<h4>
							Our factory has also obtained authoritative certifications,
							including BSCI and ISO9001, etc.,
						</h4>
						<div class="text">
							ensuring that our products meet the highest standards from raw
							material procurement to final delivery.
						</div>
					</div>
				</div>

				<div class="manu-r" :class="{ 'animate__animated animate__slideInUp':ifmanu_r}">
					<div class="top">
						<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/BSCI.png" alt="" />
						<span>BSCI</span>
					</div>
					<div class="bottom">
						<div class="zhan">
							<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/cnaseng.jpg" alt="" />
					 
							<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/cnaschi.jpg" alt="" />
						</div>
						<span style="font-weight: bold;font-size: 16px;">ISO14001</span>
						
					</div>
				</div>
			</div>

			<div class="shuzi">
				<div class="shuzi-c">
					<div class="shuzi-c-t">300+</div>
					<div class="shuzi-c-b">SKILLED WORDKER</div>
				</div>
				<div class="shuzi-c">
					<div class="shuzi-c-t">15,000m²</div>
					<div class="shuzi-c-b">MANUFACTURING FACILITIES</div>
				</div>
				<div class="shuzi-c">
					<div class="shuzi-c-t">40,000,000+</div>
					<div class="shuzi-c-b">UNITS OF PRODUCTION EVERY YEAR</div>
				</div>
			</div>
			<div class="ertit">
				<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/aboutimg7.jpg" alt="" />
				<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/aboutimg2.jpg" alt="" />
				<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/aboutimg8.jpg" alt="" />
			</div>
			<div class="exhshiw">Exhibition Showcase</div>
			<div class="show-pic"> 
				<div class="swiper-container swiper-container-aboutus">
					<div class="swiper-wrapper">
						<div class="swiper-slide swiper-slide-aboutus">
							<a href="javascript:void(0);">
								<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/huizhan/1-4.jpg" alt="" />
								<div>Apr.2023 <br>Exhibition in Ningbo</div>
							</a>
						</div>
						<div class="swiper-slide swiper-slide-aboutus">
							<a href="javascript:void(0);">
								<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/huizhan/2-4.jpg" alt="" />
								<div>Apr.2023 <br>Exhibition in Ningbo</div>
							</a>
						</div>
						<div class="swiper-slide swiper-slide-aboutus">
							<a href="javascript:void(0);">
								<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/huizhan/3-4.jpg" alt="" />
							<div>Apr.2023 <br>Exhibition in Ningbo</div>
							</a>
						</div>
						<div class="swiper-slide swiper-slide-aboutus">
							<a href="javascript:void(0);">
								<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/huizhan/4-1.jpg" alt="" />
								<div>Nov.2023<br>Exhibition in Ningbo</div>
							</a>
						</div>
						<div class="swiper-slide swiper-slide-aboutus">
							<a href="javascript:void(0);">
								<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/huizhan/5-11.jpg" alt="" />
								<div>Nov.2023<br>Exhibition in Ningbo</div>
							</a>
						</div>
						<div class="swiper-slide swiper-slide-aboutus">
							<a href="javascript:void(0);">
								<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/huizhan/6-11.jpg" alt="" />
								<div>Nov.2023<br>Exhibition in Ningbo</div>
							</a>
						</div>
						<div class="swiper-slide swiper-slide-aboutus">
							<a href="javascript:void(0);">
								<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/huizhan/7-11.jpg" alt="" />
							<div>Nov.2023<br>Exhibition in Ningbo</div>
							</a>
						</div>
						<div class="swiper-slide swiper-slide-aboutus">
							<a href="javascript:void(0);">
								<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/huizhan/8-11.jpg" alt="" />
								<div>Nov.2023<br>Exhibition in Ningbo</div>
							</a>
						</div>
					</div>
					<!-- 分页器 -->
					<div class="swiper-pagination"></div>
				</div>
				<!--导航按钮 -->
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
			</div>

			<!-- 留言表单 -->
			<div class="foot_top"  :class="{ 'animate__animated animate__slideInUp':iffoot_top}">
				<div class="zhezhao"></div>
				<div class="foot_top_left">
					<h2>Send an Inquiry for Price & Catalog.</h2>
					<div class="intro">
						A Chinese factory with more than 25 years of experience in the
						production of household products
					</div>
					<div class="tips">
						<ul>
							<li>
								<img src="~assets/img/tips1.png" alt="" />
								<span>1. We can offer very competitive price of iron household
									items.
								</span>
							</li>
							<li>
								<img src="~assets/img/tips2.png" alt="" />
								<span>2. We can ensure the stability of daily production, which can
									be stable at over 5000 sets per day.
								</span>
							</li>
							<li>
								<img src="~assets/img/tips3.png" alt="" />
								<span>3. We have a dedicated team to inspect all aspects of the
									production process.
								</span>
							</li>
							<li>
								<img src="~assets/img/tips4.png" alt="" />
								<span>4. We have a great passion for new product development and
									hope we can innovate together.
								</span>
							</li>
						</ul>
					</div>

					<div class="forwards">
						<span>Look forward to hearing from you. </span>
						<img src="~assets/img/t.png" alt="" />
					</div>
				</div>
				<div class="foot_top_right">
					<el-card>
						<el-form :model="messageForm" label-width="80px" label-position="top" :rules="messageFormRules"
							ref="messageForm">
							<el-row>
								<el-col :span="11">
									<el-form-item label="Name *" prop="name">
										<el-input v-model="messageForm.name"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="11" style="margin-left: 20px">
									<el-form-item label="Email *" prop="email">
										<el-input v-model="messageForm.email"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="11">
									<el-form-item label="Tel / Whatsapp *" prop="tel">
										<el-input v-model="messageForm.tel"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="11" style="margin-left: 20px">
									<el-form-item label="Company">
										<el-input v-model="messageForm.company"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="11">
									<el-form-item label="Country">
										<el-input v-model="messageForm.country"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="11" style="margin-left: 20px">
									<el-form-item label="Quantity">
										<el-input v-model="messageForm.quantity"></el-input>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-form-item label="Have you ever imported from China? *" style="display: flex">
									<el-radio-group v-model="messageForm.from_china" style="margin-left: 20px">
										<el-radio :label="1">Yes</el-radio>
										<el-radio :label="0">No</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-row>
							<el-row>
								<el-col>
									<el-form-item label="Message *" prop="content">
										<el-input type="textarea" :rows="5"
											placeholder="Please provide details like size,weight,destination port and etc, so that we can quote the best price for you"
											v-model="messageForm.content"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-form-item>
								<el-button @click="submitMessage">Get Free Samples and Catalogs</el-button>
							</el-form-item>
						</el-form>
					</el-card>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import "swiper/dist/js/swiper";
	import "swiper/dist/css/swiper.css";
	import Swiper from "swiper";

	export default {
		name: "AboutUs",
		data() {
			return {
				ifabout_video:false,
				ifaboutus_c:false,
				ifaboutus_d:false,
				ifaboutus_e:false,
				ifmanu_r:false,
				iffoot_top:false,
				messageForm: {
					name: "",
					email: "",
					tel: "",
					company: "",
					country: "",
					quantity: "",
					from_china: -1,
					content: "",
				},
				messageFormRules: {
					name: [{
						required: true,
						message: "",
						trigger: "blur"
					}],
					email: [{
						required: true,
						message: "",
						trigger: "blur"
					}],
					tel: [{
						required: true,
						message: "",
						trigger: "blur"
					}],
					content: [{
						required: true,
						message: "",
						trigger: "blur"
					}, ],
				},
			};
		},
		created() {
			document.title = 'ABOUT US_YROFY:WISER CHOICE BETTER HOUSE';
			window.addEventListener("scroll", this.handleScroll);
		},
		methods: {
			handleScroll() {
				const videoElement = document.querySelector(".aboutus-video"); 
				const aboutus_cElement = document.querySelector(".aboutus-main-c");
				const aboutus_dElement = document.querySelector(".aboutus-main-d");
				const aboutus_eElement = document.querySelector(".aboutus-main-e");
				const manu_rElement = document.querySelector(".manu-r");
				const foot_topElement = document.querySelector(".foot_top");
				// console.log(series_listElement)
				if (videoElement) {
					const video = videoElement.getBoundingClientRect();
					if (this.ifabout_video) {
						this.ifabout_video = true
					} else {
						this.ifabout_video = video.top <= window.innerHeight && video.bottom >= 0;
					}
				}
				if (aboutus_cElement) {
					const aboutus_c  = aboutus_cElement.getBoundingClientRect();
					if (this.ifaboutus_c) {
						this.ifaboutus_c = true
					} else {
						this.ifaboutus_c = aboutus_c.top <= window.innerHeight && aboutus_c.bottom >= 0;
					}
				}
				if (aboutus_dElement) {
					const aboutus_d  = aboutus_dElement.getBoundingClientRect();
					if (this.ifaboutus_d) {
						this.ifaboutus_d = true
					} else {
						this.ifaboutus_d = aboutus_d.top <= window.innerHeight && aboutus_d.bottom >= 0;
					}
				}
				if (aboutus_eElement) {
					const aboutus_e  = aboutus_eElement.getBoundingClientRect();
					if (this.ifaboutus_e) {
						this.ifaboutus_e = true
					} else {
						this.ifaboutus_e = aboutus_e.top <= window.innerHeight && aboutus_e.bottom >= 0;
					}
				}
				if (manu_rElement) {
					const manu_r  = manu_rElement.getBoundingClientRect();
					if (this.ifmanu_r) {
						this.ifmanu_r = true
					} else {
						this.ifmanu_r = manu_r.top <= window.innerHeight && manu_r.bottom >= 0;
					}
				}
				if (foot_topElement) {
					const foot_top  = foot_topElement.getBoundingClientRect();
					if (this.iffoot_top) {
						this.iffoot_top = true
					} else {
						this.iffoot_top = foot_top.top <= window.innerHeight && foot_top.bottom >= 0;
					}
				}
			},
			initSwiper() {
				new Swiper(".swiper-container", {
					slidesPerView: 4,
					breakpoints: {
						559: {
							slidesPerView: 2,
						},
						749: {
							slidesPerView: 3,
						},
					},
					loop: true,
					spaceBetween: 10,
					autoplay: {
						//自动开始
						delay: 2500, //时间间隔
						disableOnInteraction: false, //*手动操作轮播图后不会暂停*
					},
					//分页器
					pagination: {
						el: ".swiper-pagination",
						clickable: true, // 分页器可以点击
					},
					//前进后退按钮
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				});
			},
			// 提交留言
			submitMessage() {
				this.$refs["messageForm"].validate((valid) => {
					if (valid) {
						//转换参数
						const formData = new FormData();
						for (let key in this.messageForm) {
							formData.set(key, this.messageForm[key]);
						}
						//请求发送
						messageAPI(formData).then((res) => {
							if (res.code == 200) {
								this.$message.success(res.message);
								for (let key in this.messageForm) {
									this.messageForm[key] = "";
								}
							} else {
								this.$message.error(res.message);
							}
						});
					} else {
						return false;
					}
				});
			},
		},
		mounted() {
			this.initSwiper();
		},
		components: {},
	};
</script>
<style></style>
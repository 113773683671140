import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vant from '../utils/vant.min.js'
import '@/assets/css/style.css'
import '../utils/vant_index.css'
import 'animate.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
Vue.use(vant)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'O86uj4GP03b72EsMERCHHAnMsYZmL0cQ' // 百度地图API密钥
})
// Google Map
// import * as VueGoogleMaps from 'vue2-google-maps';
//
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'YOUR_API_KEY',
//     libraries: 'places', // 如果您需要使用地点库则这样写
//   },
// });

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

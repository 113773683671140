<template>
	<div>
		<header>
			<div class="hd_p">
				<div class="hd_p_t">
					<div class="hd_p_t_l">
						<div>
							<img src="~assets/img/tele.png" alt="" />
							<span>0086-579-87329098</span>
						</div>
						<div>
							<img src="~assets/img/letter.png" alt="" />
							<span> packy@yrofy.com</span>
						</div>
					</div>

					<div class="hd_p_t_r">
						<div class="search">
							<input type="text" placeholder="Search.." />
							<button>
								<img src="~assets/img/search.png" alt="" />
							</button>
						</div>
						<div class="langue">
							<div class="xuan" @click="showLang">
								<div>
									<img :src="langue.icon" alt="" />
									<span>{{ langue.label }}</span>
								</div>
								<img src="~assets/img/down.png" alt="" class="down" />
							</div>
							<ul class="xuan-list" v-if="isShowLang">
								<li v-for="(item, index) in langueList" :key="index" @click="selectLang(item)">
									<img :src="item.icon" alt="" />
									<span>{{ item.label }}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="hd_p_b">
					<div class="hd_p_b_l">
						<a href="#" class="logo">
							<img src="~assets/img/logo.png" alt="" />
						</a>
						<div class="symbol">
							<img src="~assets/img/logo-1.png" alt="" />
						</div>
					</div>
					<div class="hd_p_b_r">
						<a href="javascript:void(0)" class="getP">GET PRICE & DETAILS</a>
						<nav id="navs">
							<a @click="$router.push('/index')" class="nav_a"
								:class="{ bian: isActive('/index') || isActive('/') }">HOME</a>
							<a @click="$router.push('/aboutUs')" class="nav_a"
								:class="{ bian: isActive('/aboutUs') }">ABOUT US</a>
							<a @click="$router.push('/product')" class="nav_a" :class="{
                  bian: isActive('/product') || isActive('/productDetail'),
                }">PRODUCT</a>
							<a @click="$router.push('/news')" class="nav_a"
								:class="{ bian: isActive('/news') || isActive('/newDetail') }">NEWS</a>
							<a @click="$router.push('/contactUs')" class="nav_a"
								:class="{ bian: isActive('/contactUs') }">CONTACT US</a>
						</nav>
					</div>
				</div>
			</div>
			<div class="hd_m">
				<div class="menu" @click="showMMenu">
					<img src="~assets/img/menu.png" alt="" />
				</div>
				<a href="#" class="logo_m"><img src="~assets/img/logo.png" alt="" /></a>
				<button @click="searchBtn">
					<img src="~assets/img/search.png" alt="" />
				</button>
				<input type="text" v-model="searchContent" v-if="searchShow" placeholder="Search…" />

				<!-- m-nav -->
				<div class="m-nav" v-if="isShowMeun">
					<div class="m-nav-l">
						<div class="close" @click="isShowMeun = false">
							<img src="~assets/img/close.png" alt="" />
						</div>
						<div class="cotanct">
							<div>
								<img src="~assets/img/tele.png" alt="" />
								<span>0086-579-87329098</span>
							</div>
							<div>
								<img src="~assets/img/letter.png" alt="" />
								<span> packy@yrofy.com</span>
							</div>
						</div>

						<nav>
							<a @click="jumpMMenu('/index')">
								<div>
									<span>HOME</span>
								</div>
								<img src="~assets/img/right.png" alt="" class="arrow-right" />
							</a>

							<a @click="jumpMMenu('/aboutUs')">
								<div>
									<span>ABOUT US </span>
								</div>
								<img src="~assets/img/right.png" alt="" class="arrow-right" />
							</a>
							<a @click="jumpMMenu('/product')">
								<div>
									<span>PRODUCT</span>
								</div>
								<img src="~assets/img/right.png" alt="" class="arrow-right" />
							</a>
							<a @click="jumpMMenu('/news')">
								<div>
									<span>NEWS</span>
								</div>
								<img src="~assets/img/right.png" alt="" class="arrow-right" />
							</a>
							<a @click="jumpMMenu('/contactUs')">
								<div>
									<span>CONTACT US</span>
								</div>
								<img src="~assets/img/right.png" alt="" class="arrow-right" />
							</a>
						</nav>

						<div class="m_langue">
							<div class="m-l-title">Select Languge</div>
							<a v-for="(item, index) in langueList" :key="index" @click="selectLang(item)">
								<img :src="item.icon" alt="" />
								<span>{{ item.label }}</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>

		<div class="main-container">
			<router-view ref="childRef" />
		</div>

		<footer>
			<div class="p_foot">
				<div class="foot_m">
					<div class="neirong">
						<div class="neiropng_l">
							<h2>Products</h2>
							<a v-for="(item, index) in product_type" :key="index"
								@click="toProductList(item)">{{ item.type_name }}</a>
						</div>
						<div class="neiropng_m">
							<h2>Contact us</h2>
							<ul>
								<li>
									<img src="~assets/img/con1.png" alt="" />
									<span>No.26 Zerong Road, Dahou Industrial Zone, Tangxian Town,<br>Yongkang City,
										Zhejiang Province, 321300, China</span>
								</li>
								<li>
									<img src="~assets/img/con2.png" alt="" />
									<span> 0086-579-87329098</span>
								</li>
								<li>
									<img src="~assets/img/con3.png" alt="" />
									<span> packy@yrofy.com</span>
								</li>
							</ul>
						</div>
						<div class="neiropng_r">
							<h2>Follow us</h2>
							<div class="share">
								<a href="#">
									<img src="~assets/img/s1.png" alt="" />
								</a>
								<a href="#">
									<img src="~assets/img/s2.png" alt="" />
								</a>
								<a href="#">
									<img src="~assets/img/s3.png" alt="" />
								</a>
								<a href="#">
									<img src="~assets/img/s4.png" alt="" />
								</a>
								<a href="#">
									<img src="~assets/img/s5.png" alt="" />
								</a>
								<a href="#">
									<img src="~assets/img/s6.png" alt="" />
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="foot_b">
					<div>
						©2023 Yongkang Guoqiangrui Industry and Trade Co.Ltd.All Rights Reserved.
						<a href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#555;marin-left:20px"> 浙ICP备2024065335号-1</a>
						<a style="color:#666;margin-left: 20px;" target="_blank" rel="noopener"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33078402101202">浙公网安备33078402101202号</a>
					</div>
				</div>
			</div>
		</footer>
		<div style="display: none;" class="bottom_btn">
			<div class="menu_active" @click="redirectTo('/index')" :class="{ 'active': activeTab === 'index' }">
				<img src="../assets/img/menu1.png" alt="">
				<!-- <div class="menu">HOME</div> -->
			</div>
			<div class="menu_active" @click="redirectTo('/aboutUs')" :class="{ 'active': activeTab === 'aboutUs' }">

				<img src="../assets/img/menu3.png" alt="">
				<!-- <div class="menu">ABOUT US</div> -->
			</div>
			<div class="menu_active" @click="redirectTo('/product')" :class="{ 'active': activeTab === 'product' }">
				<img src="../assets/img/menu2.png" alt="">
				<!-- <div class="menu">PRODUCT</div> -->
			</div>
			<div class="menu_active" @click="redirectTo('/contactUs')" :class="{ 'active': activeTab === 'contactUs' }">
				<img src="../assets/img/menu4.png" alt="">
				<!-- <div class="menu">CONTACT US</div> -->
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getProdSideListAPI
	} from "@/assets/api/projectAPI";
	export default {
		name: "Main",
		data() {
			return {
				activeTab: 'index',

				isShowLang: false,
				langue: {
					label: "ENGLISH",
					value: "en",
					icon: require("assets/img/NF-ENGLISH.png"),
				},
				langueList: [{
						label: "ENGLISH",
						value: "en",
						icon: require("assets/img/NF-ENGLISH.png"),
					},
					{
						label: "SPANISH",
						value: "spa",
						icon: "https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/NF-SPANISH.png"
					},
					{
						label: "PORTUGUESE",
						value: "pt",
						icon: require("assets/img/NF-PORTUGUESE.png"),
					},
					{
						label: "FRENCH",
						value: "fra",
						icon: require("assets/img/NF-FRENCH.png"),
					},
					{
						label: "ARABIC",
						value: "ara",
						icon: require("assets/img/NF-ARABIC.png"),
					},
					{
						label: "RUSSIAN",
						value: "ru",
						icon: require("assets/img/NF-RUSSIAN.png"),
					},
				],
				isShowMeun: false,
				searchShow: false,
				searchContent: "",
				product_type: [],
			};
		},
		methods: {
			redirectTo(route) {
				this.$router.push(route);

				this.activeTab = route.substring(1); // Remove the leading '/'
				console.log(this.activeTab)
			},
			// 语言选择
			showLang() {
				this.isShowLang = !this.isShowLang;
			},
			// 选择语言
			selectLang(item) {
				this.langue = item;
				localStorage.setItem("langue", JSON.stringify(this.langue));
				this.isShowLang = false;
				this.isShowMeun = false;
				location.reload();
			},
			//初始化语言选项
			initLangue() {
				let langue = localStorage.getItem("langue");
				if (langue != undefined && langue != null) {
					this.langue = JSON.parse(langue);
				} else {
					localStorage.setItem("langue", JSON.stringify(this.langue));
				}
			},
			// 是否是当前页面
			isActive(route) {
				return this.$route.path === route;
			},
			// 手机端跳转
			jumpMMenu(route) {
				this.$router.push(route);
				this.isShowMeun = false;
			},
			// 手机端菜单
			showMMenu() {
				this.isShowMeun = !this.isShowMeun;
			},
			searchBtn() {
				if (this.searchShow) {
					// TODO 搜索内容
					console.log(this.searchContent);
					// 搜索完成将搜索框关闭
					this.searchContent = "";
					this.searchShow = !this.searchShow;
				} else {
					// 如果没有展开搜索框就展开
					this.searchShow = !this.searchShow;
				}
			},
			getProdType() {
				getProdSideListAPI().then((res) => {
					if (res.code == 200) {
						this.product_type = res.data.product_type;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			toProductList(item) {
				if (this.$route.path == "/product") {
					const childRef = this.$refs.childRef;
					if (childRef) {
						// 调用子组件的方法
						childRef.toggleType(item); // 替换为子组件中的方法名
					}
				} else {
					this.$router.push({
						path: "/product",
						query: {
							type: item.id,
						},
					});
				}
			},
		},
		created() {
			// 尝试从本地存储中获取上一次保存的选项卡状态
			const savedTab = localStorage.getItem('activeTab') || null;
			this.activeTab = savedTab || this.activeTab;

			// 监听路由变化，更新选项卡状态
			this.$router.afterEach((to) => {
				this.activeTab = to.path.substring(1);
				// 将选项卡状态保存到本地存储
				localStorage.setItem('activeTab', this.activeTab);
			});
		},
		mounted() {
			this.initLangue();
			this.getProdType();
		},
		components: {},
	};
</script>
<style></style>
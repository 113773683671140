import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/components/Index'
import AboutUs from '@/components/AboutUs'
import ProductList from '@/components/ProductList'
import NewList from '@/components/NewList'
import ContactUs from '@/components/ContactUs'
import ProductDetail from '@/components/ProductDetail'
import NewDetail from '@/components/NewDetail'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/product',
    name: 'ProductList',
    component: ProductList
  },
  {
    path: '/productDetail',
    name: 'ProductDetail',
    component: ProductDetail
  },
  {
    path: '/news',
    name: 'NewList',
    component: NewList
  },
  {
    path: '/newDetail',
    name: 'NewDetail',
    component: NewDetail
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: ContactUs
  }
]

const router = new VueRouter({
	mode:'history',
  routes
})

//以下代码解决路由地址重复点击的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router

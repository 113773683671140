<template>
	<div class="prod">
		<div class="prod-t"></div>
		<div class="prod-all">
			<div class="prod-all-l">
				<div class="prod-cate" v-if="isWideEnough">
					<ul>
						<li>Product</li>
						<li v-for="(item, index) in product_type" :key="index" @click="toggleType(item)">
							> {{ item.type_name }}
						</li>
					</ul>
				</div>
				<div class="merch" v-if="isWideEnough">
					<div class="prod-hot">
						<div class="tilte-h">Hot Products</div>
						<ul>
							<li v-for="(item, index) in hot_product" :key="index" @click="toDetail(item.id)">
								<img :src="item.images" alt="" />
								<span>{{ item.product_name }}</span>
							</li>
						</ul>
					</div>
				</div>
			
			</div>

			<div class="prod-all-r">
				<div class="bread">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>Home</el-breadcrumb-item>
						<el-breadcrumb-item>Product Detail</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="xian"></div>

				<div class="prod-detail">
					<a @click="$router.go(-1)" class="back">
						< Back</a>
							<div class="prod-detail-c">
								<div class="prod-detail-c-l">
									<div class="merch-detail-l-img">
										<img :src="selectedImage" alt="" class="image1" />
									</div>

									<ul>
										<li v-for="(item, index) in product.images" :key="index">
											<img :src="item" alt="" @click="selectImage(item)" />
										</li>
									</ul>
								</div>
								<div class="prod-detail-c-r">
									<h2>{{ product.product_name }}</h2>
									<p v-html="product.product_desc"></p>
									<div class="introduct" v-html="product.product_content"></div>
									<!-- <div class="intro2">
                <ul>
                  <li>1 - Here is the functional description</li>
                  <li>2 - Here is the functional description</li>
                  <li>3 - Here is the functional description</li>
                </ul>
              </div> -->
									

									<a class="inquiry" @click="showForm">Inquiry</a>

									<div class="biaodan" v-if="isShowForm">
										<div class="foot_top_right">
											<el-form :model="messageForm" label-width="80px" label-position="top"
												:rules="messageFormRules" ref="messageForm">
												<el-row>
													<el-col :span="11">
														<el-form-item label="Name *" prop="name">
															<el-input v-model="messageForm.name"></el-input>
														</el-form-item>
													</el-col>
													<el-col :span="11" style="margin-left: 20px">
														<el-form-item label="Email *" prop="email">
															<el-input v-model="messageForm.email"></el-input>
														</el-form-item>
													</el-col>
												</el-row>
												<el-row>
													<el-col :span="11">
														<el-form-item label="Tel / Whatsapp *" prop="tel">
															<el-input v-model="messageForm.tel"></el-input>
														</el-form-item>
													</el-col>
													<el-col :span="11" style="margin-left: 20px">
														<el-form-item label="Company">
															<el-input v-model="messageForm.company"></el-input>
														</el-form-item>
													</el-col>
												</el-row>
												<el-row>
													<el-col :span="11">
														<el-form-item label="Country">
															<el-input v-model="messageForm.country"></el-input>
														</el-form-item>
													</el-col>
													<el-col :span="11" style="margin-left: 20px">
														<el-form-item label="Quantity">
															<el-input v-model="messageForm.quantity"></el-input>
														</el-form-item>
													</el-col>
												</el-row>

												<el-row>
													<el-form-item label="Have you ever imported from China? *"
														style="display: flex">
														<el-radio-group v-model="messageForm.from_china"
															style="margin-left: 20px">
															<el-radio :label="1">Yes</el-radio>
															<el-radio :label="0">No</el-radio>
														</el-radio-group>
													</el-form-item>
												</el-row>
												<el-row>
													<el-col>
														<el-form-item label="Message *" prop="content">
															<el-input type="textarea" :rows="5"
																v-model="messageForm.content"></el-input>
														</el-form-item>
													</el-col>
												</el-row>
											<!-- 	<el-row>
													<el-col>
														<el-form-item label="Verification code: *" class="verif-code">
															<el-input></el-input>
															<img src="" alt="">
														</el-form-item>
													</el-col>
												</el-row> -->
												<el-form-item>
													<el-button @click="submitMessage">Submit</el-button>
												</el-form-item>
											</el-form>
										</div>
									</div>
								</div>
							</div>
				</div>
				<!-- 分页 -->
				<div class="paging">
					<el-pagination class="product" background layout="prev, pager, next" @prev-click='prevclick'
						@next-click='nextclick'>
					</el-pagination>
				</div>
				<!-- <div class="merch" v-if="isWideEnough">
					<div class="prod-hot">
						<div class="tilte-h">Hot Products</div>
						<ul>
							<li v-for="(item, index) in hot_product" :key="index" @click="toDetail(item.id)">
								<img :src="item.images" alt="" />
								<span>{{ item.product_name }}</span>
							</li>
						</ul>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getProdSideListAPI,
		getProdDetailAPI,
		messageAPI
	} from "@/assets/api/projectAPI";
	export default {
		name: "ProductDetail",
		data() {
			return {
				isWideEnough: window.innerWidth > 1372,
				currentPage: 1,
				id: this.$route.query.q,
				product: {},
				productList: [{
						src: "https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/3.png"
					},
					{
						src: "https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner.png"
					},
					{
						src: "https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/3.png"
					},
					{
						src: "https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner.png"
					},
					{
						src: "https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/3.png"
					},
				],
				hot_product: [],
				product_type: [],
				isShowForm: false,
				selectedImage: '',
				messageForm: {
					name: "",
					email: "",
					tel: "",
					company: "",
					country: "",
					quantity: "",
					from_china: -1,
					content: "",
					// verifCode: "",
				},
				messageFormRules: {
					name: [{
						required: true,
						message: " ",
						trigger: "blur"
					}],
					email: [{
						required: true,
						message: " ",
						trigger: "blur"
					}],
					tel: [{
						required: true,
						message: " ",
						trigger: "blur"
					}],
					content: [{
						required: true,
						message: " ",
						trigger: "blur"
					}],
				},
			};
		},

		methods: {
			handleResize() {
			      this.isWideEnough = window.innerWidth > 1372;
				  console.log(this.isWideEnough)
			    },
			prevclick() {
				this.id = this.product.previous.id
				this.getProdDetail()
			},
			nextclick() {
				this.id = this.product.next.id
				this.getProdDetail()

			},
			toggleImg(item) {
				this.product = item;
			},
			getProdDetail() {
				getProdDetailAPI(this.id).then((res) => {
					if (res.code == 200) {
						this.product = res.data;
						this.selectedImage = res.data.images[0]
						this.ifnet()
					} else {
						this.$message.error(res.message);
					}
				});
			},
			selectImage(imagePath) {
				this.selectedImage = imagePath; // Update the selected image path
			},
			getProdType() {
				getProdSideListAPI().then((res) => {
					if (res.code == 200) {
						this.product_type = res.data.product_type;
						this.hot_product = res.data.hot_product;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			toggleType(item) {
				this.$router.push({
					path: "/product",
					query: {
						type: item.id,
					},
				});
			},
			showForm() {
				this.isShowForm = !this.isShowForm;
			},
			// 提交留言
			submitMessage() {
				this.$refs["messageForm"].validate((valid) => {
					if (valid) {
						//转换参数
						const formData = new FormData();
						for (let key in this.messageForm) {
							formData.set(key, this.messageForm[key]);
						}
						//请求发送
						messageAPI(formData).then((res) => {
							if (res.code == 200) {
								this.$message.success(res.message);
								for (let key in this.messageForm) {
									this.messageForm[key] = "";
								}
								this.isShowForm = false
							} else {
								this.$message.error(res.message);
							}
						});
					} else {
						return false;
					}
				});
			},
			toDetail(id) {
				this.$router.push({
					query: {
						q: id
					}
				}).catch((err) => {});
				this.id=id
				this.getProdDetail();
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			},
			ifnet(){
				const btnPrev = document.querySelector('.btn-prev');
				const btnnext = document.querySelector('.btn-next');
				if (btnPrev) {
					btnPrev.disabled = false;
				}
				
				// if('next' in this.product){
				// 	console.log('fasf')
				// 			btnnext.disabled = false
				// }
				if('next' in this.product){
					if(this.product.next.length!=0){
						btnnext.disabled = false
					}else{
						btnnext.disabled = true
					}
				}else{
					btnnext.disabled = false
				}
				
				if('previous' in this.product){
					if(this.product.previous.length!=0){
						btnPrev.disabled = false
					}else{
						btnPrev.disabled = true
					}
				}else{
					btnPrev.disabled = false
				}
			}
		},
		mounted() {
			 window.addEventListener('resize', this.handleResize);
			this.getProdType();
			this.getProdDetail();
		},
		components: {},
	};
</script>
<style></style>
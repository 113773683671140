<template>
	<div class="index-a">
		<div class="banner">

			<el-carousel trigger="click" :height="bannerHeight+'px'">
				<el-carousel-item v-for="(item,index) in imagear" :key="index">
					<img style="width: 100%;height: 100%;" :src="item.img" alt="">
					<div class="banner_p" v-if="item.flag==true">
						<h2>EXPERIENCED HOME MANUFACTUR</h2>
						<div>WITH</div>
						<h4><span>25</span>+YEARS OEM&ODM</h4>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="show_c" :class="{ 'animate__animated animate__fadeInUp ':ifshow_c}">
			<div class="tishi">
				<div class="tishi_l">
					<h2>" Discover our products</h2>
					<h4>Smart objects that simplify your daily life! "</h4>
				</div>
				<img src="~assets/img/smile.png" alt="" />
			</div>
		</div>
		<div class="series">
			<h2 class="sixtitle" :class="{ 'animate__animated animate__fadeInUp':ifsixtitle}">SIX SERIES OF HOME STORAGE
				PRODUCTS</h2>
			<div class="arrow_d">
				<img src="~assets/img/a-d.png" alt="" />
			</div>
			<!-- <div class="series_list" :class="{ 'animate__animated animate__slideInUp':ifseries}">
				<a href="javascript:void(0)" v-for="(item, index) in prodList" :key="index" @click="toDetail(item.id)">
					<img :src="item.images" alt="" />
					<div class="right">
						<div class="title">{{ item.product_name }}</div>
						<div class="r_m">
							{{ item.product_desc }}
						</div>
						<div class="click_n">check more</div>
					</div>
				</a>
			</div> -->
			
			<van-tree-select height="150vw" :items="prodList" :main-active-index.sync="active" @navclick="onNavClick">
			  <template #content>
			 <!--   <van-image
			         v-for="(product, index) in getSelectedTypeProducts()"
			         :key="index"
			         :src="product.images"
			       /> -->
				   <div v-for="(itm, idx) in getSelectedTypeProducts()" :key="idx"  class="vant_product-item product-item">
				     <div class="product-content vant_product-content">
				       <img class="product-image vant_product-image" :src="itm.images" alt="">
				       <div class="product-info vant_product-info">
				         <div class="product-title vant_product-title">{{itm.text}}</div>
				         <div class="product-description vant_product-description">{{itm.product_desc}}</div>
				         <div class="click-btn vant_click-btn" @click="toDetail(itm.id)">Check More</div>
				       </div>
				     </div>
				   </div>
				    
			  <!-- <div>
				   <van-image
				     v-if="active === 1"
				     src="https://img01.yzcdn.cn/vant/apple-2.jpg"
				   />
			   </div> -->
			  </template>
			</van-tree-select>
			
			
			
			<template>
				<el-tabs class="custom-tabs" style="margin-top: 80px;" v-model="activeName" @tab-click="handleClick">
				  <el-tab-pane v-for="(item, index) in prodList" :key="index" :label="item.text" :name="item.type_id.toString()">
				    <div v-for="(itm, idx) in item.list" :key="idx" v-if="idx < item.itemsToShow" class="product-item">
				      <div class="product-content">
				        <img class="product-image" :src="itm.images" alt="">
				        <div class="product-info">
				          <div class="product-title">{{itm.text}}</div>
				          <div class="product-description">{{itm.product_desc}}</div>
				          <div class="click-btn" @click="toDetail(itm.id)">Check More</div>
				        </div>
				      </div>
				    </div>
				    <div v-if="item.list.length > item.itemsToShow" class="load-more">
				      <span @click="loadMore(index)">MORE PRODUCTS</span>
				    </div>
				  </el-tab-pane>
				</el-tabs> 
			</template>
			<div class="series_list" :class="{ 'animate__animated animate__slideInUp':ifseries}">

			</div>
		</div>

		<div class="feel" :class="{ 'animate__animated animate__slideInUp':iffeel}">
			<span>Feel free to contact us for catalogs and free samples.</span>
			<img src="~assets/img/btn.png" alt="" />
		</div>

		<div class="about_us" :class="{ 'animate__animated animate__slideInUp':ifabout_us}">
			<div class="about_m">
				<div class="about_m_l">
					<img src="~assets/img/fang.png" alt="" class="img1" />
					<el-carousel class="img2 imghti" trigger="click" height="inherit">
						<el-carousel-item v-for="(item,index) in imgbanner" :key="index">
							<img style="width: 100%;height: 100%;" :src="item.img" alt="">
						</el-carousel-item>
					</el-carousel>
					<!-- <img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/3.png" alt="" class="img2" /> -->
				</div>
				<div class="about_m_r" :class="{ 'animate__animated animate__slideInUp':ifabout_us}">
					<h2>ABOUT US</h2>
					<div class="fu_title">
						Yrofy is your most trusted partner in wholesale home storage
						products.
					</div>
					<div class="m_title">
						We've been producing<br>household products for over
					</div>
					<div class="b_title">25 years OEM & ODM</div>
					<div class="di_title">
						<div>Here at Yrofy, We has won the unanimous trust of our global customers through our strong
							production capacity and strict product quality control.</div>
						<div>
							We believe that a tidy house is the guarantee of a light mind, so we specialize in combining
							the consumer demand with production skills. We always keep up with the trend of the global
							market to provide innovative and high quality products, such as clothes tree, hanger, cart
							and all kinds of shelves, which gained consumers'recognition and love in the international
							market.
						</div>
						<div>Wiser choice makes better house.</div>
						<div>We can OEM your products according to your different quality requirement.</div>
					</div>
				</div>
			</div>
		</div>

		<div class="choose" :class="{ 'animate__animated animate__slideInUp':ifchoose}">
			<h2>WHY CHOOSE US</h2>
			<div class="choose_list">
				<ul>
					<li>
						<img src="~assets/img/c1.png" alt="" />
						<div>Source factory</div>
					</li>
					<li>
						<img src="~assets/img/c2.png" alt="" />
						<div>Advanced production equipment</div>
					</li>
					<li>
						<img src="~assets/img/c3.png" alt="" />
						<div>Strict production process</div>
					</li>
					<li>
						<img src="~assets/img/c4.png" alt="" />
						<div>Quality assurance</div>
					</li>
				</ul>
			</div>
		</div>

		<div class="show-b" :class="{ 'animate__animated animate__slideInUp':scrolledToTarget}">
			<div class="number">
				<div v-for="(item, index) in numberList1" :key="index" class="number_y">
					<div class="number-qty">
						<span>{{ item.prefix }}</span>
						<!--   <animate-number
              :key="item.qty"
              duration="3000"
              :from="0"
              :to=" item.qty"
            ></animate-number> -->
						<span v-scroll-number="item.qty"> </span>
						<span>{{ item.suffix }}</span>
					</div>
					<span class="number-desc">{{ item.desc }}</span>
				</div>
			</div>
			<!-- <div class="number">
        <div v-for="(item, index) in numberList2" :key="index">
          <div class="number-qty">
            <span>{{ item.prefix }}</span>
            <animate-number
              :key="item.qty"
              duration="3000"
              :from="0"
              :to="item.qty"
            ></animate-number>
            <span>{{ item.suffix }}</span>
          </div>
          <span class="number-desc">{{ item.desc }}</span>
        </div>
      </div> -->
			<div class="mask"></div>
		</div>
		<!-- 评价区 -->
		<div class="evaluate" :class="{ 'animate__animated animate__slideInUp':ifevaluate}">
			<!-- 标题 -->
			<div class="evaluate-top">GOOD REVIEWS FROM OUR CUSTOMERS</div>
			<!-- 下面 -->
			<div class="eva_c">
				<div class="evaluate-left">
					<div class="comments_t">
						<img src="~assets/img/shou.png" alt="" />
						<img src="~assets/img/shou.png" alt="" />
					</div>



					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide" delay='true' v-for="(item, index) in reviews" :key="index">
								<div class="user_c">
									<p v-html="item.content"></p>
								</div>
								<div class="user-info">
									<div class="info-c">
										<span class="info-1">{{ item.name }}</span>
										<span class="info-2">&nbsp;&nbsp;from&nbsp;&nbsp;</span>
										<span class="info-1">{{ item.country }}</span>
									</div>
									<el-avatar :src="item.image"></el-avatar>
								</div>
							</div>
						</div>
						<!-- 分页器 -->
						<div class="swiper-pagination"></div>
					</div>




				</div>
				<div class="evaluate-right">
					<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/pics-1.png" alt="" class="img1" />
					<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/pics-2.png" alt="" class="img2" />
					<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/pics-3.png" alt="" class="img3" />
					<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/pics-4.png" alt="" class="img4" />
					<img src="https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/pics-5.png" alt="" class="img5" />
				</div>
			</div>
		</div>

		<div class="partenrs" :class="{ 'animate__animated animate__slideInUp':ifpartenrs}">
			<h2>PARTNERS</h2>
			<div class="partenrs_list">
				<div class="name_p">Europe</div>
				<div class="s_list">
					<a href="#">
						<img src="~assets/img/p1.jpg" alt="" />
					</a>
					<a href="#">
						<img src="~assets/img/p2.jpg" alt="" />
					</a>
					<a href="#">
						<img src="~assets/img/p3.jpg" alt="" />
					</a>
					<a href="#">
						<img src="~assets/img/p4.jpg" alt="" />
					</a>
					<a href="#">
						<img src="~assets/img/p5.jpg" alt="" />
					</a>
				</div>
			</div>
			<div class="name_all">
				<div class="name_a_l">
					<div>America</div>
					<a href="#">
						<img src="~assets/img/p6.jpg" alt="" />
					</a>
				</div>
				<div class="name_a_l">
					<div>Russia</div>
					<a href="#">
						<img src="~assets/img/p7.jpg" alt="" />
					</a>
				</div>
				<div class="name_a_l">
					<div>Japan</div>
					<a href="#">
						<img src="~assets/img/p8.jpg" alt="" />
					</a>
				</div>
				<div class="name_a_l">
					<div>Southeast Asia</div>
					<a href="#">
						<img src="~assets/img/p9.jpg" alt="" />
					</a>
					<a href="#">
						<img src="~assets/img/p10.jpg" alt="" />
					</a>
				</div>
			</div>
		</div>

		<!-- 留言表单 -->
		<div class="foot_top" :class="{ 'animate__animated animate__slideInUp':iffoot_top}">
			<div class="zhezhao"></div>
			<div class="foot_top_left">
				<h2>Send an Inquiry for Price & Catalog</h2>
				<div class="intro">
					A Chinese factory with more than 25 years of experience in the
					production of household products.
				</div>
				<div class="tips">
					<ul>
						<li>
							<img src="~assets/img/tips1.png" alt="" />
							<span>1. We can offer very competitive price of iron household items.
							</span>
						</li>
						<li>
							<img src="~assets/img/tips2.png" alt="" />
							<span>2. We can ensure the stability of daily production, which can
								be stable at over 5000 sets per day.
							</span>
						</li>
						<li>
							<img src="~assets/img/tips3.png" alt="" />
							<span>3. We have a dedicated team to inspect all aspects of the
								production process.
							</span>
						</li>
						<li>
							<img src="~assets/img/tips4.png" alt="" />
							<span>4. We have a great passion for new product development and hope
								we can innovate together.
							</span>
						</li>
					</ul>
				</div>

				<div class="forwards">
					<span>Look forward to hearing from you. </span>
					<img src="~assets/img/t.png" alt="" />
				</div>
			</div>
			<div class="foot_top_right">
				<el-card>
					<el-form :model="messageForm" label-width="80px" label-position="top" :rules="messageFormRules"
						ref="messageForm">
						<el-row>
							<el-col :span="11">
								<el-form-item label="Name *" prop="name">
									<el-input v-model="messageForm.name"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="11" style="margin-left: 20px">
								<el-form-item label="Email *" prop="email">
									<el-input v-model="messageForm.email"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="11">
								<el-form-item label="Tel / Whatsapp *" prop="tel">
									<el-input v-model="messageForm.tel"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="11" style="margin-left: 20px">
								<el-form-item label="Company">
									<el-input v-model="messageForm.company"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="11">
								<el-form-item label="Country">
									<el-input v-model="messageForm.country"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="11" style="margin-left: 20px">
								<el-form-item label="Quantity">
									<el-input v-model="messageForm.quantity"></el-input>
								</el-form-item>
							</el-col>
						</el-row>

						<el-row>
							<el-form-item label="Have you ever imported from China? *" style="display: flex">
								<el-radio-group v-model="messageForm.from_china" style="margin-left: 20px">
									<el-radio :label="1">Yes</el-radio>
									<el-radio :label="0">No</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-row>
						<el-row>
							<el-col>
								<el-form-item label="Message *" prop="content">
									<el-input type="textarea" :rows="5"
										placeholder="Please provide details like size,weight,destination port and etc, so that we can quote the best price for you"
										v-model="messageForm.content"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-form-item>
							<el-button @click="submitMessage">Get Free Samples and Catalogs</el-button>
						</el-form-item>
					</el-form>
				</el-card>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getHomePageProjectAPI,
		getHomePageReviewsAPI,
		messageAPI,
	} from "@/assets/api/projectAPI";

	import "swiper/dist/js/swiper";
	import "swiper/dist/css/swiper.css";
	import Swiper from "swiper";

	export default {
		name: "Index",

		data() {
			return {
				active: 0,
				      items: [{ text: '分组 1' }, { text: '分组 2' }],
				ifseries: false,
				iffeel: false,
				ifabout_us: false,
				ifchoose: false,
				ifevaluate: false,
				ifpartenrs: false,
				iffoot_top: false,
				ifsixtitle: false,
				ifshow_c: false,
				bannerHeight: 0,
				bannerHrt: 0,
				activeName: '1',
				scrolledToTarget: false,
				itemsToShow: 3,
				prodList: [],
				numberList1: [{

						prefix: "",
						suffix: "+",
						qty: 40000000,
						desc: "UNITS OF PRODUCTION EVERY YEAR",
					},
					{
						prefix: "",
						suffix: "+",
						qty: 40,
						desc: "EXPORT TO COUNTRIES WORLDWIDE",
					},
					{
						prefix: "$",
						suffix: "+",
						qty: 700000,
						desc: "ACCUMULATED CAPITAL INVESTMENT",
					},
					{
						prefix: "",
						suffix: "m²",
						qty: 15000,
						desc: "MANUFACTURING FACILITIES",
					},
					{
						prefix: "",
						suffix: "+",
						qty: 10000,
						desc: "AVERAGE DAILY SHIPMENTS",
					},
					{
						prefix: "",
						suffix: "+",
						qty: 300,
						desc: "SKILLED WORDKER",
					},
				],
				reviews: [],
				imgbanner: [{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner/1.jpg'
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner/2.jpg'
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner/3.jpg'
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner/4.jpg'
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner/5.jpg'
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner/6.jpg'
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner/7.jpg'
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner/8.jpg'
					},

				],
				imagear: [{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner.png',
						flag: true
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner2.jpg'
					},
					{
						img: 'https://yrofy.oss-us-west-1.aliyuncs.com/yrofy/banner3.jpg'
					}
				],
				messageForm: {
					name: "",
					email: "",
					tel: "",
					company: "",
					country: "",
					quantity: "",
					from_china: -1,
					content: "",
				},
				messageFormRules: {
					name: [{
						required: true,
						message: "",
						trigger: "blur"
					}],
					email: [{
						required: true,
						message: "",
						trigger: "blur"
					}],
					tel: [{
						required: true,
						message: "",
						trigger: "blur"
					}],
					content: [{
						required: true,
						message: "",
						trigger: "blur"
					}],
				},
			};
		},

		directives: {
			'scroll-number': {
				bind(el, binding) {
					const targetValue = binding.value;
					const duration = 6000; // 3秒
					const startTime = performance.now();

					function updateNumber(timestamp) {
						const progress = timestamp - startTime;
						let currentValue;

						if (progress >= duration) {
							currentValue = targetValue; // Set to target value when animation is complete
						} else {
							currentValue = Math.round(easeInOutQuad(progress, 0, targetValue, duration));
						}

						// 更新元素的显示值
						el.innerText = currentValue.toLocaleString();

						if (progress < duration) {
							requestAnimationFrame(updateNumber);
						}
					}

					// 缓动函数，使用四次方根曲线
					function easeInOutQuad(t, b, c, d) {
						t /= d / 2;
						if (t < 1) return (c / 2) * t * t + b;
						t--;
						return (-c / 2) * (t * (t - 2) - 1) + b;
					}

					// 开始滚动数字
					requestAnimationFrame(updateNumber);
				},
			},
		},

		created() {
			document.title = 'YROFY:WISER CHOICE BETTER HOUSE';
			window.addEventListener("scroll", this.handleScroll);
		},
		methods: {
			getSelectedTypeProducts() {
			      // 根据选中的类型索引返回当前类型下的所有产品
			      return this.prodList[this.active]?.list || [];
			    },
			onNavClick(index) {
			      console.log(index);
			    },
			loadMore(index) {
				// 加载更多条目
				this.prodList[index].itemsToShow += 3;
			},
			handleClick(tab, event) {
				console.log(this.activeName);
			},
			handleScroll() {
				const targetElement = document.querySelector(".show-b");
				const series_listElement = document.querySelector(".series_list");
				const feelElement = document.querySelector(".series_list");
				const about_usElement = document.querySelector(".about_us");
				const chooseElement = document.querySelector(".choose");
				const evaluateElement = document.querySelector(".evaluate");
				const partenrsElement = document.querySelector(".partenrs");
				const foot_topElement = document.querySelector(".foot_top");
				const sixtitleElement = document.querySelector(".sixtitle");
				const show_cElement = document.querySelector(".show_c");

				// console.log(series_listElement)
				if (targetElement) {
					const rect = targetElement.getBoundingClientRect();
					if (this.scrolledToTarget) {
						this.scrolledToTarget = true
					} else {
						this.scrolledToTarget = rect.top <= window.innerHeight && rect.bottom >= 0;
					}
				}
				if (series_listElement) {
					const ser_list = series_listElement.getBoundingClientRect();
					if (this.ifseries) {
						this.ifseries = true
					} else {
						this.ifseries = ser_list.top <= window.innerHeight && ser_list.bottom >= 0;
					}
				}
				if (feelElement) {
					const feel_list = feelElement.getBoundingClientRect();
					if (this.iffeel) {
						this.iffeel = true
					} else {
						this.iffeel = feel_list.top <= window.innerHeight && feel_list.bottom >= 0;
					}
				}
				if (about_usElement) {
					const about_us_list = about_usElement.getBoundingClientRect();
					if (this.ifabout_us) {
						this.ifabout_us = true
					} else {
						this.ifabout_us = about_us_list.top <= window.innerHeight && about_us_list.bottom >= 0;
					}
				}

				if (chooseElement) {
					const choose_list = chooseElement.getBoundingClientRect();
					if (this.ifchoose) {
						this.ifchoose = true
					} else {
						this.ifchoose = choose_list.top <= window.innerHeight && choose_list.bottom >= 0;
					}
				}
				if (evaluateElement) {
					const evaluate_list = evaluateElement.getBoundingClientRect();
					if (this.ifevaluate) {
						this.ifevaluate = true
					} else {
						this.ifevaluate = evaluate_list.top <= window.innerHeight && evaluate_list.bottom >= 0;
					}
				}
				if (partenrsElement) {
					const partenrs_list = partenrsElement.getBoundingClientRect();
					if (this.ifpartenrs) {
						this.ifpartenrs = true
					} else {
						this.ifpartenrs = partenrs_list.top <= window.innerHeight && partenrs_list.bottom >= 0;
					}
				}
				if (foot_topElement) {
					const foot_top_list = foot_topElement.getBoundingClientRect();
					if (this.iffoot_top) {
						this.iffoot_top = true
					} else {
						this.iffoot_top = foot_top_list.top <= window.innerHeight && foot_top_list.bottom >= 0;
					}
				}
				if (sixtitleElement) {
					const sixtitle_list = sixtitleElement.getBoundingClientRect();
					if (this.ifsixtitle) {
						this.ifsixtitle = true
					} else {
						this.ifsixtitle = sixtitle_list.top <= window.innerHeight && sixtitle_list.bottom >= 0;
					}
				}
				if (show_cElement) {
					const ifshow_c_list = show_cElement.getBoundingClientRect();
					if (this.ifshow_c) {
						this.ifshow_c = true
					} else {
						this.ifshow_c = ifshow_c_list.top <= window.innerHeight && ifshow_c_list.bottom >= 0;
					}
				}
			},



			updateDynamicTo() {
				// 在这里进行动态更新
				let title = this.formatNumber(20000000)
				this.numberList1[0].qty = title
				// 例如，你可以在这里调用 formatNumber 方法
				// this.dynamicTo = formatNumber(/* 新的值 */);
			},
			formatNumber(value) {
				// 使用 toLocaleString 方法将数字格式化为带逗号的形式
				return value.toLocaleString();
			},
			// 提交留言
			submitMessage() {
				this.$refs["messageForm"].validate((valid) => {
					if (valid) {
						//转换参数
						const formData = new FormData();
						for (let key in this.messageForm) {
							formData.set(key, this.messageForm[key]);
						}
						//请求发送
						messageAPI(formData).then((res) => {
							if (res.code == 200) {
								this.$message.success(res.message);
								for (let key in this.messageForm) {
									this.messageForm[key] = "";
								}
							} else {
								this.$message.error(res.message);
							}
						});
					} else {
						return false;
					}
				});
			},
			initSwiper() {
				new Swiper(".swiper-container", {
					slidesPerView: 1,
					loop: false,
					//分页器
					pagination: {
						el: ".swiper-pagination",
						clickable: true, // 分页器可以点击
					},
					autoplay: {
						delay: 5000,
						disableOnInteraction: false,

					},
				});
			},

			toDetail(id) {
				this.$router.push({
					path: "/productDetail",
					query: {
						q: id,
					},
				});
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			},
		},
		mounted() {
			// 首次加载时,初始化高度
			this.screenWidth = window.innerWidth
			this.bannerHeight = 600 / 1150 * this.screenWidth
			// 窗口大小发生改变
			window.onresize = () => {
				this.screenWidth = window.innerWidth
				this.bannerHeight = 600 / 1050 * this.screenWidth
			}
			if (window.innerWidth < 500) {
				this.ifsixtitle = true
				this.ifshow_c = true
			}
			getHomePageProjectAPI().then((res) => {
				if (res.code == 200) {
					
					
					//处理数据，将type_name字段改为text
					const modifiedData = res.data.map(category => ({
					  ...category,
					  text: category.type_name,
					  list: category.list.map(item => ({
					    ...item,
					    text: item.product_name
					  }))
					}));
					
					// 删除原始的type_name字段
					modifiedData.forEach(category => {
					  delete category.type_name;
					  category.list.forEach(item => {
					    delete item.product_name;
					  });
					});
					this.prodList=modifiedData
					
					
					
					
					
					
					this.prodList = this.prodList.map((item) => ({
						...item,
						itemsToShow: this.itemsToShow,
					}));
					
					console.log(this.prodList);
					// 根据窗口宽度设置ifseries和ifsixtitle
					if (window.innerWidth < 500) {
						this.ifseries = true;
						this.ifsixtitle = true;
					}
				} else {
					this.$message.error(res.message);
				}
			});
			getHomePageReviewsAPI().then((res) => {
				// if (res.code == 200) {
					this.reviews = res.data;
				// } else {
				// 	this.$message.error(res.message);
				// }
			});
			// 请求完数据 初始化swiper
			setTimeout(() => {
				this.initSwiper();
			}, 2000);
		},
		components: {},
	};
</script>
<style></style>